<template>
  <div class="menu-container">
    <div v-for="(menu, index) in menus" :key="index" @click="handleClick(menu)">
      <div>
        <img :src="menu.imgSrc">
      </div>
      <div>{{ menu.text }}</div>
    </div>
  </div>
</template>
<script>
export default ({
  name: 'menuItem',
  methods: {
    handleClick(menu) {
      this.$emit('menu-clicked', menu);
    }
  },
  props: {
    menus: {
      type: Array,
      required: true
    }
  }
});
</script>
<style scoped>
.menu-container {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: start;
}
.menu-container > div {
  display: grid;
  grid-template-columns: 10px 30px 1fr;
  grid-column-gap: 10px;

  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-container > div :first-child {
  height: 100%;
  width: 100%;
  grid-column: 2;
}

.menu-container > div :nth-child(2) {
  height: 100%;
  width: 100%;
}

.menu-container > div:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.5);
}
</style>
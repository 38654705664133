<template>
  <video class="video-background" autoplay loop muted>
    <source src="../../../assets/login.mp4" type="video/mp4">
    您的浏览器不支持 HTML5 视频标签。
  </video>
  <div class="container">
    <div class="login-wrapper">
      <div class="header">Time</div>
      <div class="form-wrapper">
        <input type="text" name="username" @keyup.enter="login" placeholder="username" class="input-item" v-model="username">
        <input type="password" name="password" @keyup.enter="login" placeholder="password" class="input-item" v-model="password">
        <input type="text" name="verifyCode" placeholder="请输入验证码" class="input-item" v-if="codeSee"
               v-model="verifyCode" @keyup.enter="login">
        <div class="btn btn__ball-collision"  tabindex="0" @click="login" >Login</div>
      </div>
      <div class="msg">
        Don't have account?
        <a href="#">Sign up</a>
      </div>
    </div>
  </div>
  <div class="site-footer">
    备案号：<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024091653号-1</a>
  </div>
</template>

<script>
import httpClient from "@/components/axios/axiosTiming.vue";

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      verifyCode: null,
      codeSee: false
    }
  },
  methods: {
    login() {
      login(this)
    }
  },
  watch: {
    username() {
      this.codeSee = false
    },
    password() {
      this.codeSee = false
    }
  },
  mounted() {
    // localStorage.getItem('token') && this.$router.push({ name: 'Timing' });
  }
}

async function login(componentInstance) {
  const url = '/auth/login'; // 替换为你的API URL
  const data = {
    email: componentInstance.username,
    password: componentInstance.password,
    VerificationCode: componentInstance.verifyCode
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const response = await httpClient.post(url, data, config);
  if (!response || response.data === null){
    return
  }
  const resData = response.data;
  if (resData.needCode !== null && resData.needCode === true){
    componentInstance.codeSee = true
    return
  }
  localStorage.setItem('token',resData.token)
  componentInstance.$router.push({ name: 'Timing' });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .site-footer {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
  }
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频覆盖整个容器 */
  z-index: -1; /* 确保视频在背景层 */
}
.container {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  align-items: center;
  box-sizing: border-box;
}

.login-wrapper {
  display: flex;
  background-image: linear-gradient(to right, rgba(166, 193, 238, 0.6), rgba(251, 194, 235, 0.6)); /* 50%透明度 */
  border-radius: 15px;
  height: 500px;
  width: 360px;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  line-height: 200px;
}

.input-item {
  width: 80%;
  border: 0;
  margin-bottom: 10px;
  padding: 10px 10px;
  border-bottom: 1px solid rgb(128, 125, 125);
  font-size: 15px;
  border-radius: 15px;
  background-image: linear-gradient(to right, rgba(251, 194, 235, 0.6), rgba(166, 193, 238, 0.6));
  outline: none;
}

.input-item ::placeholder {
  text-transform: uppercase;
}

.btn {
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
  border-radius: 15px;
  margin-left: 20%;
  width: 60%;
  height: 20%;
  margin-top: 40px;
  cursor: pointer; /* 设置鼠标指针为手形 */
  background-image: linear-gradient(to right, rgba(251, 194, 235, 0.6), rgba(166, 193, 238, 0.6));
  color: #fff;
}

.msg {
  text-align: center;
  line-height: 88px;
}

a {
  text-decoration-line: none;
  color: #abc1ee;
}

@keyframes crissCrossLeft {
  0% {
    left: -20px
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes crissCrossRight {
  0% {
    right: -20px
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}


.btn__ball-collision {
  position: relative;
  overflow: hidden;
  z-index: 1;


  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background-image: linear-gradient(to right, rgba(251, 194, 235, 0.6), rgba(166, 193, 238, 0.6));
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.6s;
    z-index: -1;
  }

  &::before {
    left: -20px;
    transform: translate(-50%, -50%);
  }

  &::after {
    right: -20px;
    transform: translate(50%, -50%);
  }

  &:hover {
    &::before {
      opacity: 1;
      animation: crissCrossLeft .8s both;
    }

    &::after {
      opacity: 1;
      animation: crissCrossRight .8s both;
    }
  }
}
</style>

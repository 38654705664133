import Page_404 from "@/components/error/Page404";

const errorRoutes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: Page_404
    }
];

export default errorRoutes;
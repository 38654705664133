<template>
  <div className="not-found">
    <h1>404</h1>
    <p>抱歉，您访问的页面不存在。</p>
    <router-link to="/login">返回首页</router-link>
  </div>
</template>

<script>
export default {
  name: 'Page_404'
}
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
}

.not-found h1 {
  font-size: 10rem;
  margin: 0;
}

.not-found p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.not-found a {
  text-decoration: none;
  color: #007bff;
  font-size: 1.2rem;
}

.not-found a:hover {
  text-decoration: underline;
}
</style>
<template>
  <div  :class="['menu', { 'menu-collapsed': isCollapsed }]">
    <div>
      <div :class="['toggleButton', { 'toggleButtonBack': isCollapsed }]" @click="toggleMenu"></div>
    </div>
    <div v-show="!isCollapsed" class="menu-body">
      <div>小可爱的相册</div>
      <menuItem :menus="menusData" @menu-clicked="redirectTo"/>
    </div>
    <uploadButton :show-button="!isCollapsed" />
  </div>
</template>

<script>
import menuItem from '@/components/main/menu/menuItem.vue';
import uploadButton from "@/components/main/menu/uploadButton.vue";

export default {
  name: 'menuPage',
  components: {
    menuItem,
    uploadButton
  },
  methods: {
    redirectTo(selectComponent){
      const param = {
        props: {
          fileId: 0
        },
        component: selectComponent.component
      }
      this.$emit('menu-clicked', param);
    },
    toggleMenu(){
      this.isCollapsed = !this.isCollapsed;
    }
  },
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menusData: this.menus,
      isCollapsed: false
    };
  }
};
</script>

<style scoped>
.menu-body{
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-gap: 100px;
  background-image: linear-gradient(113.54935006524363deg, rgba(51, 69, 79,1) 4.83%,rgba(31, 41, 47,0.5) 98.03%);
  border-radius: 10px;
}
.menu-body > div:first-child{
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 20px;
  font-weight: bold;
  background-image: linear-gradient(112.61986494804046deg, rgba(248, 198, 215,1) 4.937238493723849%,rgba(175, 233, 249,1) 97.82426778242677%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic; /* 使字体倾斜 */
  text-align: center;
}

.menu {
  grid-area: m;
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr 30px;
  justify-content: center;
  align-content: center;
  width: 200px;
  transition: width 0.3s;
  overflow: hidden;
}

.menu-collapsed {
  width: 30px;
}
.toggleButton {
  background-image: url("../../../assets/svg/menu.svg");
  background-size: 100% 100%;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  justify-self: center; /* 水平方向靠右 */
  align-self: center; /* 垂直方向居中 */
  cursor: pointer;
}

.toggleButtonBack {
  background-image: url("../../../assets/svg/menu.svg");
  background-size: 100% 100%;
  height: 30px;
  width: 100%;
  border-radius: 10px;
  justify-self: center; /* 水平方向靠右 */
  align-self: center; /* 垂直方向居中 */
}

.menu > div:first-child {
  display: grid;
}
</style>
<script>
import {createRouter, createWebHistory} from 'vue-router';
import errorRoutes from "@/components/router/error/errorRouter";
import loginRouter from "@/components/router/login/loginRouter";
import photoRoutes from "@/components/router/photo/phoneRouter";

const routes = [
    ...errorRoutes,
    ...loginRouter,
    ...photoRoutes

];

const TimingRouter = createRouter({
  history: createWebHistory(),
  routes
});

// 导航守卫
TimingRouter.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  // 检查路由是否需要身份验证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果没有Token，重定向到登录页面
    if (!token) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default TimingRouter;
</script>


import { createApp } from 'vue'
import App from './App.vue'
import TimingRouter from "@/components/router/timingRouter.vue";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 安装路由之后，在main.js引入路由器

const app = createApp(App)
app.use(TimingRouter).use(ElementPlus);
app.mount('#app');

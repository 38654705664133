<template>
  <div class="upload-button" @click="openDialog" v-if="showButton"></div>
  <el-dialog v-model="dialogVisible" width="30%" :before-close="handleClose">
    <template #header>
      <span>记录美好时光吧</span>
      <el-button @click="closeDialog" class="close-button" icon="el-icon-close"></el-button>
    </template>
    <el-upload
        ref="upload"
        class="upload-demo"
        method="post"
        :headers="customHeaders"
        :action="uploadUrl"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :http-request="customUpload"
        list-type="picture-card"
        :on-progress="handleProgress"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        multiple
        :limit="100"
        :on-exceed="handleExceed"
    >
      <template #default>
        <el-button size="small" type="primary">点击上传</el-button>
      </template>
      <template #tip>
        <div class="el-upload__tip">只能上传图片或视频，且不超过500MB</div>
      </template>
    </el-upload>
    <div v-if="uploading" class="progress-container">
      <el-progress :percentage="uploadProgress"></el-progress>
    </div>
  </el-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import httpClient from '@/components/axios/axiosTiming.vue'
import axios from "axios";
import eventBus from '@/components/main/album/albumMitt.js';
import albumPage from '@/components/main/album/content/albumPage.vue';

export default {
  name: 'UploadButton',
  props: {
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploadUrl: '',
      dialogVisible: false,
      fileList: [],
      uploading: false,
      customHeaders: '',
      uploadProgress: 0,
      responseUploadUrls: [],
      currentUploadNum: 0,
      totalUploadNum: 0,
      folderId: '0'
    };
  },
  mounted() {
    eventBus.on('upload-photo', this.openDialog);
    eventBus.on('upload-dir', (dir) => {
      this.folderId = dir;
    });
    this.customHeaders = {
      'open-token': localStorage.getItem('token')
    }
  },
  methods: {
    async openDialog() {
      if (!this.folderId || this.folderId === '-1'){
        return;
      }
      this.dialogVisible = true;
      const response = await httpClient.get('/file/url/upload', {
        params: {
          parentId: this.folderId,
          count: 100,
          platform: 0
        }
      });
      if (!response) {
        this.dialogVisible = false;
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '获取上传链接失败',
        });
        return;
      }
      if (response.code !== 0) {
        this.dialogVisible = false;
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: response.msg,
        });
        return;
      }
      // 设置文件的上传 URL
      this.dialogVisible = true;
      this.responseUploadUrls = response.data;
      this.currentUploadNum = 0;
      this.totalUploadNum = 0;
    },
    closeDialog() {
      const param = {
        props: {
          fileId: this.folderId
        },
        component: albumPage
      }
      eventBus.emit('callAlbumChange', param);
      this.$refs.upload.clearFiles();
      this.dialogVisible = false;
    },
    handlePreview(file) {
      console.log('Preview:', file);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.totalUploadNum++;
      if (this.totalUploadNum === fileList.length) {
        this.$refs.upload.clearFiles();
        this.dialogVisible = false;
        const param = {
          props: {
            fileId: this.folderId
          },
          component: albumPage
        }
        eventBus.emit('callAlbumChange', param);
      }
    },
    handleProgress(event) {
      this.uploading = true;
      this.uploadProgress = Math.round(event.percent);
      if (event.percent === 100) {
        setTimeout(() => {
          this.uploading = false;
        }, 1000);
      }
    },
    handleSuccess(response, file, fileList) {
      if (!response || response.code !== 0) {
        return false;
      }
      this.handleRemove(file, fileList);
    },
    handleError(err, file, fileList) {
      // 更新 fileList
      this.fileList = fileList.filter(f => f.uid !== file.uid);
      this.handleRemove(file, fileList);
    },
    beforeUpload(file) {
      const isJPGOrVideo = file.type.startsWith('image') || file.type.startsWith('video')
      const isLt500K = file.size / 1024 / 1024 / 1024 < 0.5;

      if (!isJPGOrVideo) {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '只能上传图片和视频',
        });
      }
      if (!isLt500K) {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '上传文件大小不能超过 500MB!',
        });
      }
      if (!isJPGOrVideo || !isLt500K) {
        this.dialogVisible = false;
        return false;
      }
      return true;
    },
    async customUpload({file, onProgress, onSuccess, onError}) {
      try {
        const uploadUrl = this.responseUploadUrls[this.currentUploadNum].body;
        this.currentUploadNum++;
        const formData = new FormData();
        formData.append('file', file);
        await axios.post(uploadUrl, formData, {
          headers: {
            ...this.customHeaders,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress({percent: percentCompleted});
            this.uploadProgress = percentCompleted;
          }
        }).then(result => {
          if (result.status === 200) {
            onSuccess(result.data, file);
          } else {
            onError(new Error('上传失败'));
          }
        }).catch(error => {
          onError(error);
        });
      } catch (error) {
        onError(error);
      }
    },
    handleExceed() {
      this.dialogVisible = false;
      Swal.fire({
        icon: 'error',
        title: '出错啦...',
        text: '最多只能上传 100 个文件',
      });
    },
    handleClose(done) {
      this.closeDialog();
      done();
    }
  }
};
</script>

<style scoped>
.upload-button {
  position: relative;
  background-image: url("../../../assets/svg/upload.svg"),
  linear-gradient(113.54935006524363deg, rgba(51, 69, 79, 1) 4.83%, rgba(31, 41, 47, 0.5) 98.03%);
  background-size: 100% 100%;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  justify-self: center; /* 水平方向靠右 */
  align-self: center; /* 垂直方向居中 */
  cursor: pointer;
}

.upload-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.upload-demo {
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.progress-container {
  margin-top: 20px;
}
</style>
import LoginPage from "@/components/main/login/LoginPage";
const loginRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/',
        redirect: '/login'
    }
];

export default loginRoutes;
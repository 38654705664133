,<template>
  <div class="album-container" @mousedown="startSelection" @mouseup="endSelection" @scroll="handleScroll">
    <button class="go-back" v-if="this.currentProps.fileId !== '0' && this.currentProps.fileId !== 0 " @click="goBack"></button>
    <div class="batch-delete-button">
      <div @click="photoDelete" v-if="isBatchSelectMode">删除</div>
      <div @click="cancel" v-if="isBatchSelectMode">取消</div>
    </div>
    <div class="album-body" >
      <photoPage v-for="(data, index) in photoData" :key="index" :photo-data="data" @openModel="openModal(data)"
                 :is-batch-select-mode="isBatchSelectMode" :can-select="canSelect" />
    </div>
    <photo-modal v-if="showModal" :visible="showModal" :photo="selectedPhoto" @close="closeModal"/>
  </div>
  <div v-if="menuVisible" :style="menuStyle" class="context-menu">
    <ul>
      <li @click="createNewDir">新建相册</li>
      <li @click="deletePhoto">删除</li>
      <li @click="batchClickPhoto">批量删除</li>
      <li @click="uploadPhoto">上传</li>
    </ul>
  </div>
</template>

<script>
import httpClient from "@/components/axios/axiosTiming.vue";
import photoPage from "@/components/main/album/content/photoPage.vue";
import photoModal from "@/components/main/album/content/photoModal.vue";
import eventBus from '@/components/main/album/albumMitt.js';
import albumPage from "@/components/main/album/content/albumPage.vue";
import Swal from "sweetalert2";
export default {
  name: 'albumPage',

  components: {
    photoPage,
    photoModal
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {
        return {
          fileId: '0',
          parentId: '0'
        };
      }
    }
  },
  data() {
    return {
      menuVisible: false,
      batchSelectPhotos: [],
      menuStyle: {
        top: '0px',
        left: '0px'
      },
      canSelect: false,
      photoData: [],
      showModal: false,
      selectedPhoto: null,
      selectPhoto: null,
      queryData: {
        folderId: this.currentProps.fileId,
        pageSize: 50,
        pageNum: 1,
        orderBy: 'fileType',
        isAsc: false
      },
      hasMoreData: {
        more: true
      },
      isBatchSelectMode: false, // 批量删除模式状态
    };
  },
  methods: {
    async createNewDir(){
      Swal.fire({
        title: '请输入相册名称',
        input: 'text',
        inputLabel: '相册',
        inputPlaceholder: '在此输入相册名称',
        showCancelButton: true,
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value) {
            return '相册名称不能为空！'
          }
        }
      }).then(async (result) => {
        await httpClient.post('/file/dir/create', {
          parentId: this.queryData.folderId,
          fileDirName: result.value
        });
        const param = {
          props: {
            fileId: this.queryData.folderId
          },
          component: albumPage
        }
        eventBus.emit('callAlbumChange', param);
      });

    },
    cancel(){
      this.isBatchSelectMode = false;
      eventBus.emit('cancel-select');
    },
    async photoDelete(){
      Swal.fire({
        title: '您确定吗?',
        text: "您将无法撤销此操作！",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '是的，删除它！',
        cancelButtonText: '取消'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await httpClient.post('/file/delete', this.batchSelectPhotos);
          this.goAlbum(this.queryData.folderId);
        }
      })
    },
    addSelectPhoto(selectPhoto){
      if (selectPhoto.isSelected) {
        this.batchSelectPhotos.push(selectPhoto.fileId);
      } else {
        this.batchSelectPhotos = this.batchSelectPhotos.filter(item => item !== selectPhoto.fileId);
      }
    },
    async deletePhoto(){
      Swal.fire({
        title: '您确定吗?',
        text: "您将无法撤销此操作！",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '是的，删除它！',
        cancelButtonText: '取消'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const photos = [this.selectPhoto.fileId];
          await httpClient.post('/file/delete', photos);
          this.goAlbum(this.queryData.folderId);
          Swal.fire(
              '已删除！',
              '您的文件已被删除。',
              'success'
          )
        }
      })
    },
    batchClickPhoto(){
      this.isBatchSelectMode = !this.isBatchSelectMode;
    },
    uploadPhoto() {
      eventBus.emit('upload-photo');
    },
    openModal(photo) {
      if (photo && photo.fileType === 1) {
        this.goAlbum(photo.fileId)
        return;
      }
      this.selectedPhoto = photo;
      this.showModal = true;
    },
    goAlbum(id){
      const param = {
        props: {
          fileId: id,
        },
        component: albumPage
      }
      eventBus.emit('callAlbumChange', param);
    },
    goBack() {
      this.goAlbum(this.currentProps.parentId);
    },
    closeModal() {
      this.showModal = false;
    },
    async loadPhotoData() {
      return await httpClient.post('/file/list', {
        folderId: this.queryData.folderId,
        query: {
          pageSize: this.queryData.pageSize,
          pageNum: this.queryData.pageNum,
          orderByColumn: [
            {
              orderBy: 'id',
              isAsc: this.queryData.isAsc,
            },
            {
              orderBy: this.queryData.orderBy,
              isAsc: this.queryData.isAsc,
            }
          ],
        }
      });
    },
    async loadMorePhotos(more) {
      if (!more) {
        return;
      }
      const resData = await this.loadPhotoData();
      if (!resData || resData.code !== 0) {
        return;
      }
      if (!resData.data || !resData.data.records || resData.data.records.length === 0) {
        this.hasMoreData.more = false;
        return;
      }
      this.photoData.push(...resData.data.records);
      this.queryData.pageNum++;
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.loadMorePhotos(this.hasMoreData.more);
      }
    },
    startSelection(event) {
      if (!this.isBatchSelectMode) {
        return;
      }
      this.canSelect = true;
      event.preventDefault();
    },
    endSelection() {
      if (!this.isBatchSelectMode) {
        return;
      }
      this.canSelect = false;
    },
    hideMenuContext(){
      this.menuVisible = false;
    }
  },
  mounted() {
    eventBus.on('select-photo', this.addSelectPhoto);
    eventBus.on('showMenu', (eventProps) => {
      const menuEvent = eventProps.event;
      this.menuVisible = true;
      this.selectPhoto = eventProps.photoData;
      this.menuStyle = {
        top: `${menuEvent.clientY}px`,
        left: `${menuEvent.clientX}px`
      };
    });
    const container = document.querySelector('.album-container');
    container.addEventListener('scroll', this.handleScroll);
    this.loadMorePhotos(this.hasMoreData.more = true);
    document.addEventListener('click', this.hideMenuContext);
  },
  unmounted() {
    document.removeEventListener('click', this.hideMenuContext);
  }
};
</script>

<style scoped>
.batch-delete-button{
  position: absolute;
  width: 300px;
  height: 40px;
  top: 20px;
  right: 70px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.batch-delete-button >div{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  background-image: linear-gradient(112.56486541656488deg, rgba(55, 70, 76,1) 4.937238493723849%,rgba(33, 41, 44,1) 97.82426778242677%);
  font-size: 20px;
  font-weight: bold;
  color: white;
  transition: all 0.3s;
}
.batch-delete-button >div:hover{
  scale: 1.1;
}
.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 130px;
  z-index: 1000;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  border-radius: 10px;
  background-color: #f0f0f0;
}
.go-back{
  background-image: url('../../../../assets/svg/goBack.svg');
  background-size: 100% 100%;
  position: fixed;
  background-color: transparent;
  border: none;
  width: 80px;
  height: 30px;
  top: 10px;
  left: 200px;
  cursor: pointer;
}
.album-container {
  height: 94%; /* 限制高度以使滚动条出现 */
  width: 100%;
  margin-top: 40px;
  margin-right: 20px;

  overflow: auto; /* 允许滚动 */
  -ms-overflow-style: none; /* 适用于Internet Explorer和Edge */
  scrollbar-width: none; /* 适用于Firefox */
}

.album-container::-webkit-scrollbar {
  display: none; /* 适用于Chrome, Safari和Opera */
}

.album-body {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: 270px;
  grid-gap: 20px;
}


</style>
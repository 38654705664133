<template>
  <div :class="['phone-container', {  'selected': isSelected }]" @contextmenu.prevent="showContextMenu($event)"
       @click="handleClick" @mouseenter="selection">
    <input type="checkbox" class="photo-checkbox" v-model="isSelected" v-if="isBatchSelectMode">
    <div v-if="photoData.fileType === 0">
      <div v-if="mediaType.startsWith('image')">
        <img :src="imageSrc" style="width: 100%; height: 100%" :alt="photoData.originalFileName">
      </div>
      <div v-else-if="mediaType.startsWith('video')" class="video-container">
        <img :src="imageSrc" style="width: 100%; height: 100%" :alt="photoData.originalFileName" class="video-class">
        <div class="video-icon"></div>
        <div class="overlay"></div>
      </div>
      <div v-else>
        <img src="../../../../assets/svg/notFindPhoto.svg" style="width: 100%; height: 100%"
             :alt="photoData.originalFileName">
      </div>
    </div>
    <div class="folder-container" v-else-if="photoData.fileType === 1">
      <img src="../../../../assets/photo/dirPhoto.png" style="width: 100%; height: 100%"
           :alt="photoData.originalFileName">
      <div class="folder-name" style="width: 150px; height: auto;"> {{photoData.originalFileName}} </div>
    </div>
    <div v-else>
      <img src="../../../../assets/svg/notFindPhoto.svg" style="width: 100%; height: 100%"
           :alt="photoData.originalFileName">
    </div>
  </div>
</template>

<script>
import eventBus from '@/components/main/album/albumMitt.js';

export default {
  name: 'photoPage',
  props: {
    photoData: {
      type: Object,
      required: true
    },
    isBatchSelectMode: {
      type: Boolean,
      required: true
    },
    canSelect: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isSelected: false,
      mediaType: '',
      imageSrc: ''
    };
  },
  mounted() {
    eventBus.on('cancel-select',()=>{
      this.isSelected = false
    });
    this.loadImage();
  },
  methods: {
    selection() {
      if (this.isBatchSelectMode && this.canSelect) {
        this.isSelected = !this.isSelected;
        const selectData = {
          fileId: this.photoData.fileId,
          isSelected: this.isSelected
        };
        eventBus.emit('select-photo', selectData);
      }
    },
    showContextMenu(event) {
      if (this.isBatchSelectMode) {
        return;
      }
      const eventProps = {
        photoData: this.photoData,
        event: event
      };
      eventBus.emit('showMenu', eventProps);
    },
    handleClick() {
      if (this.isBatchSelectMode) {
        this.isSelected = !this.isSelected;
        const deleteData = {
          fileId: this.photoData.fileId,
          isSelected: this.isSelected
        };
        eventBus.emit('select-photo', deleteData);
        return;
      }
      this.$emit('openModel', this.photoData);
    },
    async loadImage() {
      this.mediaType = this.photoData.mediaType;
      if (!this.photoData.thumbnail) {
        this.imageSrc = require('../../../../assets/svg/notFindPhoto.svg');
        return;
      }
      this.imageSrc = this.photoData.thumbnail;
    }
  }
};
</script>

<style scoped>
.phone-container > div {
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease; /* 平滑过渡效果 */
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.phone-container > div > div {
  height: 100%;
  width: 100%;
}

.phone-container > div > img {
  border-radius: 20px;
  border: 1px solid black;
}

.phone-container > div > div > img {
  border-radius: 20px;
  border: 1px solid black;
}

.phone-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 10px;
}

.phone-container > div:hover {
  transform: scale(1.1) rotate(3deg); /* 放大并旋转 */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  cursor: pointer;
}

.folder-container {
  position: relative;
}

.folder-name {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  bottom: 10px;
  left: 25px;
  color: #2f3640;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.video-container {
  position: relative;
}

.video-class {
  position: relative;
}

.video-icon {
  position: absolute;
  background-image: url('../../../../assets/svg/playVideoIcon.svg');
  top: 50%; /* 设置为50%将元素的顶部对齐容器的中心 */
  left: 50%; /* 设置为50%将元素的左边对齐容器的中心 */
  transform: translate(-50%, -50%); /* 使用transform向上和向左各偏移元素自身的50%，实现完全居中 */
  background-size: 100% 100%;
  height: 50px;
  width: 50px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色覆盖层 */
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.video-container:hover .overlay {
  opacity: 1; /* 鼠标悬停时显示覆盖层 */
}

.photo-checkbox {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 100;
}

.phone-container.selected {
  border-radius: 20px;
  background: #808e9b;
}
</style>
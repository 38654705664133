<template>
  <div class="account-container">
    账号
  </div>
</template>
<script>
export default {
  name: 'accountPage',
  props: {
    currentProps: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.account-container {
  display: grid;
  grid-auto-rows: 50px;
  grid-gap: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: start;
  height: 100%;
  width: 100%;
}
</style>
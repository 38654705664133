<script>
import axios from 'axios';
import Swal from 'sweetalert2'

// 创建一个Axios实例
const httpClient = axios.create({
  baseURL: 'https://faiyaayo.site/timing', // 替换为你的API基础URL
  timeout: 1000,
});

// 添加请求拦截器
httpClient.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token'); // 从localStorage中获取token
      if (token) {
        config.headers['open-token'] = token; // 设置Authorization头
      }
      return config;
    },
    error => {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '服务器请求错误',
        });
      } else if (error.request) {
        return;
      } else {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '请求失败:' + error.message,
        });
      }
    }
);

httpClient.interceptors.response.use(
    response => {
      const data = response.data;
      if (!data || data.code === null){
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '系统繁忙,请稍后再试',
        });
      }
      if (data.code !== 0) {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: data.msg,
        });
      }
      return response.data; // 正确返回响应对象
    },
    error => {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '服务器请求错误',
        });
      } else if (error.request) {
        return;
      } else {
        Swal.fire({
          icon: 'error',
          title: '出错啦...',
          text: '请求失败:' + error.message,
        });
      }
    }
);

export default httpClient;
</script>

<style scoped>

</style>
<template>
  <div class="container">
    <menuPage :menus="menus" @menu-clicked="changeMainPage" />
    <component :is="currentComponent" :key="componentKey" :currentProps="currentProps"/>
  </div>
</template>

<script>
import menuPage from '@/components/main/menu/menuPage.vue';
import accountPage from '@/components/main/album/content/accountPage.vue';
import albumPage from '@/components/main/album/content/albumPage.vue';
import eventBus from '@/components/main/album/albumMitt.js';
import httpClient from "@/components/axios/axiosTiming.vue";

export default {
  name: 'mainPage',
  components: {
    menuPage,
  },
  data() {
    return {
      menus: [
        { imgSrc: require('@/assets/svg/menuAlbum.svg'), text: '相册', component: albumPage},
        { imgSrc: require('@/assets/svg/menuAccount.svg'), text: '账号', component: accountPage }
      ],
      componentKey: 0,
      currentComponent: albumPage,
      currentProps: {
        fileId: '0',
        parentId: '0'
      },
      getFileParentId(id){
        const fileRes = httpClient.get('/file/single', {
          params: {
            fileId:id,
          }
        });
        if (!fileRes || fileRes.code !== 0) {
          return '0';
        }
        return fileRes.data.parentId;
      }
    };
  },
  mounted() {
    eventBus.on('callAlbumChange', this.changeMainPage);
  },
  methods: {
    sendUploadDir(fileId) {
      eventBus.emit('upload-dir', fileId);
    },
    changeMainPage(param) {
      this.currentComponent = param.component;
      if (this.currentComponent.name !== albumPage.name) {
        this.currentProps = {};
        this.componentKey++;
        this.sendUploadDir('-1');
        return;
      }
      if (!param.props) {
        this.currentProps = {
          fileId: '0',
          parentId: '0'
        }
        this.sendUploadDir('0');
      }else {
        this.currentProps = param.props  // 替换 currentProps 对象
        this.currentProps.parentId = this.getFileParentId(param.props.fileId);
        this.sendUploadDir(this.currentProps.fileId);
      }
      this.componentKey++;
    }
  }
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  height: 100vh;
  min-height: 800px;
  min-width: 1200px;
  background-image: url("../../../assets/back/back.jpg");
  background-size: 100% 100%;
  grid-template-areas:
    "m c"
}

.content {
  grid-area: c;
  justify-content: center;
  align-content: center;
}
</style>
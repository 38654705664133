import menuPage from "@/components/main/album/mainPage.vue";

const photoRoutes = [
  {
    path: '/timing',
    name: 'Timing',
    component: menuPage,
    meta: { requiresAuth: true } // 需要登录
  }
];

export default photoRoutes;
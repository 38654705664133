<template>
  <div class="modal-overlay" @click="close">
    <div class="modal-content" @click.stop>
      <img v-if="isImage" :src="imageSrc" :alt="photo.originalFileName" />
      <video v-else controls :src="imageSrc" />
    </div>
  </div>
</template>

<script>
import httpClient from "@/components/axios/axiosTiming.vue";

export default {
  props: {
    photo: Object
  },
  computed: {
    isImage() {
      return this.photo.mediaType.startsWith('image');
    }
  },
  data() {
    return {
      imageSrc: ''
    };
  },
  emits: ['close'],
  mounted() {
    this.loadImage();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async loadImage() {
      const fileType = this.photo.fileType
      if (fileType === 1){
        this.imageSrc = require('../../../../assets/photo/dirPhoto.png');
        return;
      }
      const resData = await this.fetchImage(this.photo.fileId);
      if (!resData || resData.code !== 0 || !resData.data || !resData.data.downloadUrl) {
        this.imageSrc = require('../../../../assets/svg/notFindPhoto.svg');
        return;
      }
      this.imageSrc = resData.data.downloadUrl;
    },
    async fetchImage(id) {
      return await httpClient.get('/file/url/download', {
        params: {
          fileId: id,
          platform: 0
        }
      });
    }
  }

}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 5px;
  border-radius: 10px;
}

.modal-content img,
.modal-content video {
  border-radius: 30px;
  max-width: 60vw;
  max-height: 80vh;
}
</style>